<template>
  <div class="container mt-5 pt-5">
    <p class="text-center pt-5">
      <span class="material-icons-outlined display-3 text-main">error_outline</span>
    </p>
    <h3 class="text-center">線上點餐服務系統更新中</h3>
    <p class="text-center py-3 text-muted">感謝您的來訪，目前線上點餐服務系統更新中，請稍後再試。</p>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import LineService from "@/services/line.service";

export default {
  name: "LineLogin",
  components: {},
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  emits: ["onLoading"],
  setup(props, { emit }) {
    const router = useRouter();
    sessionStorage.removeItem("token");
    return {};
  },
};
</script>
